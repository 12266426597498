* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Trebuchet MS", sans-serif;
}

.logo img {
  width: 80px;
}

.logo {
  display: flex;
  align-items: center;
}

.nav-links {
  list-style: none;
  display: flex;
  margin-left: auto;
}

.nav-links a {
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
  color: white;
}

.nav-links li:hover {
  background-color: white;
  border-radius: 3px;
}

.nav-links li:hover a {
  color: rgb(107, 164, 207);
}
.nav-container, footer .navbar-container{
  z-index: 9999;
}
.nav-container,footer .navbar-container, .exp .navbar {
  display: flex;
  flex-direction: row; /* add this line */
  align-items: center;
  padding: 10px;
  background-color: rgb(158, 202, 236);
  position: relative;
  
  justify-content: space-between;  /* Ensures the maximal distance */
}


footer .navbar-container .logo-cont,
footer .navbar-container .frame {
  display: inline-flex;
  align-items: center;
}

.frame {
  margin-left: auto;
  margin-right: 10px;
}
.toggle-button {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 21px;
}

.toggle-button .bar {
  width: 30px;
  height: 3px;
  margin: 5px 0;
  background-color: white;
  display: block;
  transition: all 0.3 ease;
}

@media (max-width: 768px) {
  .toggle-button {
    display: inline-block;
    right: 0;
  }
/*
  .nav-container, footer .navbar-container, .exp .navbar {
    padding: 20px;
  }*/

  .nav-links a {
    font-size: 20px;
    
  }
  .logo img{
    width: 80px;
  }

  .nav-item:first-child {
    margin-top: 0px; /* Adjust the distance between the first item and the logo/menu icon */
  }
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    right: 0;
    background-color: rgb(158, 202, 236);
    width: 100%;
    align-items: center;
    justify-content: left;
    top:60px;
  }

  .nav-links.active {
    display: flex;
  }
  
}

.toggle-button.change .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
  transition: transform 0.3s ease-out;
}

.toggle-button.change .bar:nth-child(2) {
  opacity: 0;
}

.toggle-button.change .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
  transition: transform 0.3s ease-out;
}
