/* about.css */
body {
  font-family: "Trebuchet MS", sans-serif;
  color: white;
 /* line-height: 1.6;*/
  background-color: white;
}


.contact {
  background-color: rgb(158, 202, 236);
}
.about-section {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 1em;
  padding-bottom: 10px;
}

.centered-frame {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
}

.frame-image {
  border: 7px solid rgb(255, 255, 255);
  width: 150px;
  margin: auto;
  margin-top: 15px;
}

.frame-image img {
  display: flex;
  width: 100%;
  height: auto; /* Maintain aspect ratio of the image */
}

.quick-look-section {
  text-align: left;;
  display: flex;
  align-items: center;
}


.quicklook-title {
  font-size: 1.2em;
  color: white;
  margin-bottom: 0.5em;
}

.story-item a:link,
.story-item a:visited,
.about-career-section a:link,
.about-site-section a:link,
.about-site-section a:visited,
.about-career-section a:visited {
  color: #3e7fbf; 
  font-weight: bold; 
  text-decoration: none;
}

.story-item a:hover,
.story-item a:active,
.quicklook-title a:hover,
.quicklook-title a:active,
.about-career-section a:hover,
.about-site-section a:hover,
.about-site-section a:active,
.about-career-section a:active {
  color: #3c6c8f; 
  text-decoration: underline; 
}

.centered-frame {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column; /* Stacks children vertically by default */
}

.frame-image {
  border: 7px solid rgb(255, 255, 255);
  width: 150px;
  margin: auto;
  margin-top: 10px;
}

.frame-image img {
  display: block; /* Change from flex to block to display image correctly */
  width: 100%;
  height: auto; /* Maintain aspect ratio of the image */
}

.quick-look-section {
  text-align: left;
  margin:50px;
  display: flex;
  flex-direction: column; /* Stacks children vertically by default */
  align-items: center; /* Centers children horizontally */
}

/* Media query for tablet to desktop sized screens */
@media (min-width: 768px) {
  .quick-look-section {
    flex-direction: row; /* Set children to be side by side */
    align-items: flex-start; /* Aligns top of children with top of container */
  }

  .centered-frame {
    flex-direction: row; /* Set children to be side by side */
  }
}
