* {
  font-family: "Trebuchet MS", sans-serif;
}
.p {
  letter-spacing: 0.2rem;
}
.story-container {
  margin: 0;
}
.story-item {
  position: relative;
  margin: 0;
  width: 100%; /* or set a maximum width if required*/
}
body,
html {
  margin: 0;
  padding: 0;
}
.story-text {
  font-size: 3em; /* adjust this to your desired initial size */
  opacity: 0;
  display: flex;
  flex-direction: column;
  font-weight: 450;
  transform: translateY(-50%) scale(0.8);
  transition: transform 0.5s ease-in-out;
}
.story-text.show {
  transform: translateY(0) scale(1);
}
.story-text p {
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-weight: 10;
}
/* Optional: Add this to make your images responsive and fit the div */
.story-item img {
  width: 100%;
  height: auto;
  display: block;
}

.story-item {
  position: relative;
}
.skills, .exp, .about{
  background-color: #8bb8dd;
}
.story-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* color depending on your image */
  opacity: 0; /* It's important to set initial opacity to 0 */
  transition: opacity 0.1s ease-in-out;
}
@media only screen and (max-width: 961px) {
  .story-text {
    z-index: 1;
    top: 50%;
    left: 50%;
    height: fit-content; /* Adjust as per need */
    width: 80%; /* Adjust as per need */
    position: absolute;
    transform: translate(-50%, -50%); /* To center the text */

    /* Use flex to vertically center child elements and set direction */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2em; /* adjust font size to a more readable level */
  }
}

@media only screen and (max-width: 1100px) {
  .story-text {
    z-index: 1;
    top: 50%;
    left: 50%;
    height: fit-content; /* Adjust as per need */
    width: 80%; /* Adjust as per need */
    position: absolute;
    transform: translate(-50%, -50%); /* To center the text */

    /* Use flex to vertically center child elements and set direction */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1em;
    font-size: 1em; /* adjust font size to a more readable level */
  }
}

@media only screen and (max-width: 768px) {
  .story-text {
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 80%; /* Adjust as per need */
    position: absolute;

    transition: transform 0.5s ease-in-out, font-size 0.2s ease-in-out; /* adjust transition */
    height: 90%; /* Increase from 70% to 90% */
    font-size: 1.8em; /* increase font size */
    /* Use flex to vertically center child elements and set direction */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.8em; /* adjust font size to a more readable level */
    /*overflow: scroll;*/
    line-height: 1.2;
  }
  
}

@media only screen and (max-width: 600px) {
  /*
  .story-item:first-child .story-text{
    transition: transform 0.1s ease-in-out;
  }*/
  
  .story-text {
    transition: transform 0.5s ease-in-out;
    height: 90%; /* Adjust as per need */
    width: 80%; /* Adjust as per need */
    font-size: 1em; /* decrease font size for smaller screens */
    line-height: 1.2;
  }
}