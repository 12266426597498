body {
  font-family: "Trebuchet MS", sans-serif;
  color: white;
/*  line-height: 1.6;*/
}
.navbar {
  top: 0;
  width: 100%;
  background-color: rgb(158, 202, 236);
  z-index: 10;
}
.navbar a {
  margin: 0 15px;
}

.about-career-section,
.about-site-section,
.about-me-section,
.experience-section,
.resume-section,
.skills-section {
  margin: 20px 0;
  padding: 20px;
  box-sizing: border-box;
}
.about-career-section,
.about-site-section,
.about-me-section,
.about-section-p1,
.skills-section {
  margin: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #3e7fbf;
  border: 2px solid rgb(158, 202, 236);
  border-radius: 15px;
}

.about-career-section,
.about-site-section,
.about-me-section,
.experience-section,
.projects-section,
.skills-section,
.university-section,
.extracurricular-section {
  margin-bottom: 20px; /* Adding some space between sections */
  padding: 14px;
  border: 2px solid rgb(158, 202, 236);
  border-radius: 15px;
  box-sizing: border-box;
  background-color: #3e7fbf;
}

.about-career-section,
.about-site-section,
.about-me-section,
.job-experience,
.project,
.skill,
.activity {
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(158, 202, 236);
  border: 1px solid white;
  margin-top: 10px;
}
.about-career-section:hover,
.about-site-section:hover,
.about-me-section:hover,
.job-experience:hover,
.project:hover,
.skill:hover,
.activity:hover {
  transform: scale(1.03); /* Enlarge the box to 103% of its original size */
  transition: transform 0.3s ease-in-out; /* Animation effect */
}
.about-header,
.job-title,
.project-title,
.skill-category,
.activity-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: white;
  letter-spacing: 5px;
}

.job-title h3,
.project-title h3,
.activity-title h3 {
  margin: 0;
  color: white;
  letter-spacing: 5px;
}

.company-title,
.project-name,
.activity-name {
  font-size: 1.4em;
  color: white;
  letter-spacing: 1px;
}

.title-date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
}


.date-location-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.company-title,
.project-name,
.activity-name {
  margin-right: 10px;
}
.company-title,
.project-name,
.activity-date {
  margin-right: 10px;
}

.institution-name {
  font-size: 1.2em;
  color: #3e7fbf;
}

.job-location,
.project-location,
.activity-location {
  font-size: 1em;
  color: white;
}

.job-date,
.project-date,
.activity-date {
  font-size: 1.2em;
  color: white;
}

.job-description,
.project-description,
.activity-description {
  margin-left: 20px;
  color: white;
  line-height: 1.2;
}

.job-description li,
.skill-list li,
.project-description li,
.activity-description li {
  margin-bottom: 10px;
  color: white;
}

/* make sure text doesn't overflow on small devices */
@media screen and (max-width: 600px) {
  body {
    font-size: 0.6em;
  }
}
/* Assuming .job-title-container is the container you're referring to */
.about-header,
.project-heading,
.job-title-container {
  display: flex;
  justify-content: space-between; /* Aligns children to start and end of container */
  align-items: center; /* Centers children vertically */
  /* other styles */
  padding-top: 5px;
}

.toggle-container {
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  width: 30px; /* Width of the box */
  height: 30px; /* Height of the box */
  background-color: #3e7fbf; /* blue */
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px #80a0c0; /* Light blue */ 
  cursor: pointer;
}

.description-toggle {
  font-weight: bold;
  font-size: 20px; /* Adjust size as needed */
  user-select: none; /* Prevent text selection */
  cursor: pointer;
  outline-color: white;
  color: white;
  
}

